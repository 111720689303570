import { createApp } from 'vue'
import App from './App.vue'
import routes from './route/routes.js'
import BootstrapVueNext from 'bootstrap-vue-next'
import { registerScrollSpy } from 'vue3-scroll-spy';
import VueParticles from 'vue-particles'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

import '@/assets/scss/style.scss'
import "@/assets/css/materialdesignicons.min.css";
import "@/assets/css/pe-icon-7-stroke.css";


createApp(App)
    .use(BootstrapVueNext)
    .use(routes)
    .use(registerScrollSpy)
    .use(VueParticles)
    .mount('#app')
